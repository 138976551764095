import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import loadable from "@loadable/component"
const Carousel = loadable(() => import("@brainhubeu/react-carousel"))
import { autoplayPlugin, slidesToShowPlugin } from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"

import styled from "styled-components"
import { Link } from "gatsby"
import { ContentBox } from "../styledcomponents"
import Lazy from "../lazy"

const BlogLink = styled(Link)``
const BlogText = styled.div`
  padding: 10px;
  width: 300px;
`
const BlogTitle = styled.h3`
  margin-bottom: 20px;
`

function BlogPost({ node }) {
  return (
    <ContentBox key={node.id} node={node} padding="0" position="relative">
      {node.frontmatter.featuredImage && (
        <div style={{ height: "300px", padding: "0", marginBottom: "3em" }}>
          <GatsbyImage
            image={
              node.frontmatter.featuredImage.childImageSharp.gatsbyImageData
            }
          />
        </div>
      )}
      <BlogText>
        <BlogLink to={`/blog/${node.frontmatter.slug}`}>
          <BlogTitle>{node.frontmatter.title}</BlogTitle>
        </BlogLink>
        {
          //node.frontmatter.date
        }
        <p>{node.excerpt}</p>
      </BlogText>
    </ContentBox>
  )
}

class Card extends React.Component {
  constructor(props) {
    super(props)
    this.state = { ...props }
  }
  onChange = (e) => this.setState({ value: e.target ? e.target.value : e })

  render() {
    return (
      <Lazy>
        <Carousel
          value={this.props.value}
          onChange={this.props.onChange}
          className="topicsCarousel"
          plugins={[
            "arrows",
            "infinite",
            {
              resolve: autoplayPlugin,
              options: {
                interval: 2000,
              },
            },
            {
              resolve: slidesToShowPlugin,
              options: {
                numberOfSlides: 4,
              },
            },
          ]}
          breakpoints={{
            640: {
              plugins: [
                "infinite",
                {
                  resolve: autoplayPlugin,
                  options: {
                    interval: 2000,
                  },
                },
                {
                  resolve: slidesToShowPlugin,
                  options: {
                    numberOfSlides: 1,
                  },
                },
              ],
            },
            1060: {
              plugins: [
                "arrows",
                "infinite",
                {
                  resolve: autoplayPlugin,
                  options: {
                    interval: 2000,
                  },
                },
                {
                  resolve: slidesToShowPlugin,
                  options: {
                    numberOfSlides: 2,
                  },
                },
              ],
            },
            1380: {
              plugins: [
                "arrows",
                "infinite",
                {
                  resolve: autoplayPlugin,
                  options: {
                    interval: 2000,
                  },
                },
                {
                  resolve: slidesToShowPlugin,
                  options: {
                    numberOfSlides: 3,
                  },
                },
              ],
            },
          }}
          animationSpeed={800}
        >
          {this.state.data.allMarkdownRemark.edges.map(({ node }) => (
            <BlogPost key={node.id} node={node}>
              {node.frontmatter.featuredImage && (
                <GatsbyImage
                  image={
                    node.frontmatter.featuredImage.childImageSharp
                      .gatsbyImageData
                  }
                />
              )}

              <BlogLink to={node.fields.slug}>
                <BlogTitle>{node.frontmatter.title}</BlogTitle>
              </BlogLink>
              {
                //node.frontmatter.date
              }
              <p>{node.excerpt}</p>
            </BlogPost>
          ))}
        </Carousel>
        <ContentBox margin="0" />
      </Lazy>
    )
  }
}

export default Card
