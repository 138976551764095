import React from "react"
import styled from "styled-components"

import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

import { Container, FlexBox1, H2, P, ContentBox } from "./styledcomponents"
import globals from "./globals"

import Icons from "./icons"

const Icon = styled(Icons)`
  color: ${globals.color.lightShades};
  height: 7em;
  position: absolute;
  top: -54%;
  left: -4.5%;
  @media (max-width: 1000px) {
    left: 4.5%;
  }
`

function Box({ img, review, reviewer, platform }) {
  return (
    <FlexBox1
      style={{
        display: "flex",
        backgroundColor: globals.color.darkShades,
        color: globals.color.lightShades,
        width: "100%",
        height: "100%",
        padding: "1rem 2rem 2rem 2rem",
        alignItems: "center",
        margin: 0,
      }}
    >
      {
        //      <Img src={img} />
      }
      <div>
        <P>{review}</P>
        <P>
          <strong>{reviewer}</strong> {platform ? `via ${platform}` : ""}
        </P>
      </div>
    </FlexBox1>
  )
}

function Testimonials({ title, values }) {
  return (
    <Container backgroundColor={globals.color.main} overflow="visible">
      <div
        style={{
          width: "100%",
          margin: "4em auto",
          maxWidth: globals.media.tablet,
          overflow: "visible",
        }}
      >
        <ContentBox
          margin="0"
          padding="1rem 0 0 0"
          backgroundColor={globals.color.darkShades}
          width="100%"
          maxWidth={globals.media.tablet}
          radius="0"
          position="relative"
          overflow="visible"
        >
          <Icon icon="quoteRight" />

          <H2
            padding="0 2rem"
            color={globals.color.lightShades}
            margin="3rem 0 0 0"
          >
            {title}
          </H2>
        </ContentBox>
        <Carousel
          className="testimonials"
          showThumbs={false}
          autoPlay={false}
          infiniteLoop={false}
          interval="5000"
          showStatus={false}
        >
          {values.map(({ id, ...otherProps }) => (
            <Box key={id} id={id} {...otherProps} />
          ))}
        </Carousel>
      </div>
    </Container>
  )
}

export default Testimonials
