import React, { useState, useLayoutEffect, useRef } from "react"
import styled from "styled-components"

import { GatsbyImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import Icons from "./icons"

import globals from "./globals"
import { Container, Grid, ContentBox, P, H2, H3, Li } from "./styledcomponents"

import Button from "./button"

const Circle = styled.div``

const CircleImg = styled(GatsbyImage)`
  border-radius: 10px;
  border-style: solid;
  border-width: 10px;
  border-color: ${globals.color.main};
  overflow: hidden;
`

const Num = styled.p`
  color: transparent;
  text-align: center;
  font-size: ${(props) => props.fontSize}px;
  font-weight: 900;
  position: absolute;
  top: -${(props) => props.top}px;
  left: 0;
  right: 0;
  margin: 0 auto;
  -webkit-text-stroke: 20px #ffffff90;
`

const ServiceImg = styled(GatsbyImage)`
  transition: transform 0.3s;
  position: relative;

  &:hover {
    transform: scale(1.5);
  }
`

const ServiceDiv = styled(ContentBox)`
  max-width: ${globals.media.tablet};
  width: 90vw;

  @media (min-width: ${globals.media.mobile}) {
    width: 45vw;
    ${(props) =>
    props.gridColumnTablet && `grid-column: ${props.gridColumnTablet}`}
  }

  @media (min-width: ${globals.media.tablet}) {
    width: auto;
    ${(props) => props.gridColumn && `grid-column: ${props.gridColumn}`}
  }

  &:hover ${ServiceImg} {
    transform: scale(1.5);
  }
`

const PAbs = styled.h2`
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${globals.color.darkShades.concat("ee")};
  padding: 1em;
  margin: 0;
  writing-mode: vertical-rl;
  text-orientation: upright;
  text-transform: uppercase;
`

const H2Abs = styled(H2)`
  position: absolute;
  bottom: 0.5em;
  backdrop-filter: blur(3px);
  background-color: ${globals.color.darkShades.concat("99")};
  left: 0;
  right: 0;
  padding: 0.5em;
  margin: 0 auto;
  text-align: center;
  width: max-content;
  border-radius: 10px;
  @media (max-width: ${globals.media.flexBox2}) {
    font-size: 1em;
  }
`

const Icon = styled(Icons)`
  height: 2em;
  margin: 0;
  color: ${globals.color.main};
`

const LiIcon = styled(Icon)`
  height: 1.5em;
  margin-left: -1em;
`

const LiDone = styled(Li)`
  margin: 0.7em 0;
  list-style: none;
`
const LiSpecial = styled(LiDone)`
  margin-top: 1.5em;
  color: ${globals.color.main};
`

const Span = styled.span`
  display: inline-flex;
  &:hover {
    color: ${globals.color.main};
    cursor: pointer;
  }
`
const Checkbox = styled.button`
  margin: 1em;
  width: 2em;
  height: 2em;
  padding: 0;
`

function InvestBox({
  id,
  value,
  per,
  list,
  backgroundColor,
  special,
  isStudent,
  isPercent,
}) {
  let investment = isStudent && per === "Monat" ? value - 10 : value
  investment =
    per === "Monat" ? investment - investment * isPercent : investment
  return (
    <ContentBox
      backgroundColor={backgroundColor}
      color={globals.color.darkShades}
      width="250px"
      shadow
    >
      <H3 center>{id}</H3>

      <div style={{ display: "inline-flex" }}>
        <H3>{investment} €/</H3>
        <P style={{ alignSelf: "flex-end" }}>{per}</P>
      </div>
      <ul className="fa-ul">
        {list.map((item, index) => (
          <LiDone key={index}>
            <span>
              <LiIcon icon="check" />
            </span>
            {item}
          </LiDone>
        ))}
        {special ? (
          <LiSpecial>
            <span>
              <LiIcon icon="plus" />
            </span>
            {special}
          </LiSpecial>
        ) : undefined}
      </ul>
    </ContentBox>
  )
}

function ServiceBox({
  id,
  img,
  backgroundColor,
  to,
  ltr,
  gridColumn,
  gridColumnTablet,
  title,
  alt,
}) {
  return (
    <ServiceDiv
      backgroundColor={backgroundColor}
      color={globals.color.darkShades}
      padding="0"
      margin="0"
      position="relative"
      gridColumn={gridColumn}
      gridColumnTablet={gridColumnTablet}
    >
      <AnchorLink to={to}>
        <ServiceImg
          image={img.childImageSharp.gatsbyImageData}
          alt={alt ? alt : id.toString()}
          title={title ? title : id.toString()}
        />
        {ltr ? <H2Abs>{id}</H2Abs> : <PAbs>{id}</PAbs>}
      </AnchorLink>
    </ServiceDiv>
  )
}

function Box({ id, statement, img, backgroundColor, title, alt }) {
  const [height, setHeight] = useState(0)
  const ref = useRef(null)

  useLayoutEffect(() => {
    setHeight(ref.current.clientHeight)
  }, [])

  return (
    <ContentBox
      backgroundColor={backgroundColor}
      color={globals.color.darkShades}
      padding="0"
      position="relative"
      margin="2em"
    >
      <Circle ref={ref}>
        {img && (
          <CircleImg
            image={img.childImageSharp.gatsbyImageData}
            alt={alt ? alt : id.toString()}
            title={title ? title : id.toString()}
          />
        )}
      </Circle>
      <Num fontSize={height ? height * 1.15 : 0} top={height * 0.15}>
        {id}
      </Num>
      <P center padding="1em 1.5em 0 1.5em" color="#666">
        {statement}
      </P>
    </ContentBox>
  )
}

function IIIStep(props) {
  let backgroundColor
  let boxColor
  let color

  if (props.investment) {
    backgroundColor = globals.color.lightShades
    boxColor = "white"
  } else if (props.service) {
    backgroundColor = "white"
    boxColor = "white"
  } else {
    backgroundColor = "white"
    boxColor = "white"
    color = "#666"
  }

  const [isStudent, setIsStudent] = useState(false)
  const [isPercent, setIsPercent] = useState(0)

  function toggleStudent() {
    setIsStudent(!isStudent)
  }

  function togglePercent(percent) {
    if (percent === isPercent) {
      setIsPercent(0)
    } else {
      setIsPercent(percent)
    }
  }

  return (
    <Container backgroundColor={backgroundColor} id={props.id}>
      <H2 center>{props.title}</H2>
      {!props.investment ? (
        <P center margin="0 3em" maxWidth={globals.media.tablet} color={color}>
          {props.description}
        </P>
      ) : undefined}
      <Grid
        columns={
          props.columns ? props.columns : `repeat(${props.values.length}, 1fr)`
        }
        columnsTablet={
          props.columnsTablet
            ? props.columnsTablet
            : `repeat(${props.values.length}, 1fr)`
        }
        columnsMobile={props.columnsMobile ? props.columnsMobile : `1fr`}
      >
        {props.values.map(({ id, ...otherProps }, index) => {
          if (props.investment) {
            return (
              <InvestBox
                key={index}
                id={id}
                backgroundColor={boxColor}
                {...otherProps}
                isStudent={isStudent}
                isPercent={isPercent}
              />
            )
          } else if (props.service) {
            return (
              <ServiceBox
                key={index}
                id={id}
                ltr={props.ltr}
                backgroundColor={boxColor}
                {...otherProps}
              />
            )
          } else {
            return (
              <Box
                key={index}
                id={id}
                backgroundColor={boxColor}
                {...otherProps}
              />
            )
          }
        })}
      </Grid>
      {props.investment && !props.noPercentage ? (
        <ContentBox maxWidth={globals.media.tablet} textAlign="left">
          <Span onClick={toggleStudent}>
            <Checkbox>{isStudent ? <Icon icon="check" /> : undefined}</Checkbox>
            <P>
              Rabatte für Schüler, Studenten, Auszubildende, Polizisten und
              Rettungskräfte auf monatliche Beiträge: 10 €
            </P>
          </Span>
          <br />
          <Span onClick={() => togglePercent(0.05)}>
            <Checkbox>
              {isPercent === 0.05 ? <Icon icon="check" /> : undefined}
            </Checkbox>
            <P>5% Rabatt bei halbjährlicher Zahlung</P>
          </Span>
          <br />
          <Span onClick={() => togglePercent(0.1)}>
            <Checkbox>
              {isPercent === 0.1 ? <Icon icon="check" /> : undefined}
            </Checkbox>
            <P>10% Rabatt bei jährlicher Zahlung</P>
          </Span>
        </ContentBox>
      ) : undefined}
      <Button
        to={props.link ? props.link : "/stundenplan"}
      >
        Kostenlose Probestunde buchen
      </Button>
    </Container>
  )
}

export default IIIStep
