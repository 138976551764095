import React, { createRef, useState, useEffect } from "react"
import styled from "styled-components"
import ResponsiveEmbed from "react-responsive-embed"

import globals from "./globals"
import { Container, FlexBox1, ContentBox, H2, P } from "./styledcomponents"

import Button from "./button"

import Icons from "./icons"

const background = `linear-gradient(to bottom, ${globals.color.lightShades} 0%,${globals.color.lightShades} 58%,${globals.color.lightShades} 58%,white 58%,white 100%)`

const Icon = styled(Icons)`
  color: ${globals.color.main};
  height: 1.3em;
  margin: 0 0.2em;
`

const IconStart = styled(Icon)`
  vertical-align: -0.5em;
`

function VideoBox({ url, description, title, pathname }) {
  const [showVideo, setShowVideo] = useState(false)

  const video = createRef()

  useEffect(() => {
    const videoObserver = new IntersectionObserver(onVideoIntersection, {
      rootMargin: "100px 0px",
      threshold: 0.25,
    })
    function onVideoIntersection(entries) {
      if (!entries || entries.length <= 0) {
        return
      }

      if (entries[0].isIntersecting) {
        setShowVideo(true)
        videoObserver.disconnect()
      }
    }
    if (window && "IntersectionObserver" in window) {
      if (video && video.current) {
        videoObserver.observe(video.current)
      }
    } else {
      setShowVideo(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video])

  return (
    <Container backgroundColor={background}>
      <FlexBox1>
        <ContentBox margin="0">
          <P>{description[0]}</P>
          <P>
            <IconStart icon="quoteLeft" />
            {description[1]}
            <Icon icon="quoteRight" />
          </P>
          <H2>{title}</H2>
          <Button
            to={'/stundenplan'}
          >
            Kostenlose Probestunde buchen
          </Button>
        </ContentBox>
        <ContentBox
          ref={video}
          minWidth="40vw"
          minWidthTablet="80vw"
          alignSelf="center"
          alignSelfTablet="center"
          margin="0"
        >
          {showVideo ? (
            <ResponsiveEmbed
              src={`https://www.youtube.com/embed/${url}`}
              allowFullScreen
            />
          ) : undefined}
        </ContentBox>
      </FlexBox1>
    </Container>
  )
}

export default VideoBox
