import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

import globals from "./globals"
import { Container, H2, P, ContentBox } from "./styledcomponents"

import Button from "./button"

const BackgroundImage = styled(GatsbyImage)`
  object-fit: cover;
  height: 100%;
  width: 100%;
  transition: 0.3s ease;
  position: absolute !important;
  top: 0;
  right: 0;
  left: 0;
  opacity: 0.4;
`

function Guide({ statement, img, description, pathname }) {
  return (
    <Container position="relative" padding="0" height="100%">
      <BackgroundImage fluid={img.childImageSharp.gatsbyImageData} />

      <ContentBox position="relative" maxWidth={globals.media.flexBox2}>
        <H2 center noLine>
          {statement}
        </H2>
        {description.map((text, index) => (
          <P key={index} center>
            {text}
          </P>
        ))}
        <Button
          to={'/stundenplan'}
        >
          Kostenlose Probestunde buchen
        </Button>
      </ContentBox>
    </Container>
  )
}

export default Guide
