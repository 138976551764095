import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/hero"
import ValueBox from "../components/valuebox"
import ProblemBox from "../components/problembox"
import Guide from "../components/guide"
import TrustBox from "../components/trusbox"
import VideoBox from "../components/video"
import IIIStep from "../components/3step"
import Testimonials from "../components/testimonials"
import Posts from "../components/postCards/card"
import { ContactForm, Download } from "../components/contactform"

import FitnessImg from "../images/Fitness1.svg"
import TeamImg from "../images/Freundschaft-Teamgeist1.svg"
import SVImg from "../images/selbstverteidigung1.svg"
import StressImg from "../images/stress1.svg"

import { H2 } from "../components/styledcomponents"

const Index = ({ location, data }) => {
  return (
    <Layout pathname={location.pathname}>
      <SEO
        title="TLA - Deine Kampfsport und Yoga Schule im Münchner Norden"
        description="BJJ, Selbstverteidigung und Yoga in München - Feldmoching. Komm gerne zu einer kostenlosen Kampfsport oder Yoga Probestunde vorbei!"
      />
      <Hero
        values={[
          {
            id: 1,
            img: data.hero1,
            imgAlt: "Yoga in München",
            headline: [
              "Kampfsport und Yoga in München",
              "Hast Du heute schon trainiert?",
            ],
            text: [
              "Stress durch unseren beschleunigten Lebensstil führt häufig zu gesundheitlichen Problemen. Als qualifizierte Yoga- und Kampfsportlehrer bieten wir Stunden für alle Körpertypen, alle Level und jedes Alter an. Wenn Du mit uns trainierst, steigerst Du Deine körperliche und mentale Fitness, kannst Dich richtig auspowern und es wird garantiert nie langweilig.",
              "Fühl Dich glücklicher, gesünder und voller Energie!",
            ],
          },
          {
            id: 2,
            img: data.hero2,
            imgAlt: "MMA - Mixed Martial Arts in München",
            headline: ["MMA in München", "Mehr als Fitness!"],
            text: [
              "Um mit MMA anzufangen brauchst Du keine Fitness, die wird beim Training sehr schnell kommen. Und das Beste dabei: das Training ist abwechslungsreich, Dein Kopf wird gefordert und Du lernst Dich zu verteidigen.",
            ],
          },
          {
            id: 3,
            img: data.hero4,
            imgAlt: "MMA für Jugendliche in München",
            headline: ["MMA für Jugendliche", "Einzelsport – Teampower"],
            text: [
              "Obwohl MMA ein Einzelsport ist – man Tritt beim Wettkampf alleine an – ist ein vorankommen ohne Trainingspartner nicht möglich. Wir verwirklichen den MMA Ziel mit Teampower!",
            ],
          },
          {
            id: 7,
            img: data.hero8,
            imgAlt:
              "Therapiehündin Yuki hilft Kind beim Durchschlagen des Bretts!",
            headline: [
              "Unsere Therapiehündin liebt es zu helfen",
              "Yuki grüßt Dich!",
            ],
            text: [
              "Obwohl es immer am besten ist, an Dich selbst zu glauben, kann ein wenig Hilfe von Anderen ein großer Segen sein. Unsere Therapiehündin in Ausbildung, Yuki, liebt es alle zu begrüßen, die Kinder, die aus irgendeine Grund traurig sind zu trösten, und die Jugendliche und Erwachsene zu entspannen. Sie freut sich schon Dich auf der Matte begrüßen zu dürfen!",
            ],
          },
          {
            id: 4,
            img: data.hero3,
            imgAlt: "Kickboxen für Kinder in München",
            headline: ["Kickboxen für Kinder", "Konzentriert und Kontrolliert "],
            text: [
              "Da bei Kids Kickboxen Schläge und Tritten ein großer Teil des Trainings ausmachen, ist es von größter Bedeutung, dass die Kinder auf spielerische Art lernen statt ‚gegen einander‘, konzentriert und kontrolliert miteinander zu trainieren. Auf diese Weise verbessern sie ihre motorischen und sozialen Fähigkeiten!",
            ],
          },
          {
            id: 5,
            img: data.hero5,
            imgAlt: "BJJ für Kinder in München",
            headline: ["BJJ für Kinder", "BJJ setzt Mobbing ein Ende"],
            text: [
              "Als Selbstverteidigung auf dem Schulhof ist BJJ bestens geeignet da dieser Kampfsport ohne Schläge und Tritte auskommt. Die Kinder lernen dank Übungskämpfen sich effektiv verbal & köperlich zu wehren. Das steigert das Selbstbewusstsein und beendet Mobbing.",
            ],
          },
          {
            id: 6,
            img: data.hero6,
            imgAlt: "BJJ Jugendliche & Erwachsene in München",
            headline: [
              "BJJ für Jugendliche & Erwachsene",
              "Blitzschach - Problemlösend denken ",
            ],
            text: [
              `BJJ ist eine äußerst technische und strategische Kampfkunst. Es ist bekannt als "Arte Suave", die "sanfte Kunst", weil kleinere und schwächere Menschen größere und stärkere Gegner zur Aufgabe zwingen können. Daher sind Kraft, Größe und Geschwindigkeit lediglich Attribute. Was zählt, ist die richtige Technik und eine problemlösendes Mindset.`,
            ],
          },
          {
            id: 7,
            img: data.hero7,
            imgAlt: "Yoga in München",
            headline: [
              "Yoga in München",
              "Yoga for 'Every Body' - jeden Körpertyp",
            ],
            text: [
              "Yoga begrüßt jeden Körpertyp, jedes Alter und jedes Fitnessniveau. Ob Du Deine Zehen berühren kannst, ist daher irrelevant. Es geht vielmehr darum, was Du lernst und wie Du Dich während unsere Yogastunden fühlst. Yoga ist eine Wissenschaft, die Dich durch Atemtechniken, Körperhaltungen und Meditation dabei unterstützt, ein gesundes Leben zu führen.",
            ],
          },
        ]}
        color="#ffffff"
        buttoncolor="#f17915"
        pathname={location.pathname}
      />
      <ValueBox
        values={[
          {
            id: 1,
            value: "Steigere Dein Selbstvertrauen ",
            statement:
              "Vergiss nie, Du bist mutiger als Du glaubst, stärker als Du scheinst und klüger als Du denkst. In unserer sicheren Umgebung kannst Du Deine Komfortzone erweitern und damit Dein Selbstvertrauen erhöhen.",
            img: SVImg,
            imgAlt: "Selbstverteidigung Icon",
          },
          {
            id: 2,
            value: "Werde Teil das Teams",
            statement: (
              <>
                In <Link to="/ueber-uns/">unserem Team</Link> arbeiten wir
                gemeinsam daran, unseren persönlichen Zielen zu erreichen.
                Erfolg kommt durch großartigen Teamgeist.
              </>
            ),
            img: TeamImg,
            imgAlt: "Team Icon",
          },
          {
            id: 3,
            value: "Fühle Dich gesünder und fitter!",
            statement:
              "Beim Kampfsport und Yoga werden Glückshormone ausgeschüttert. Du bist, was Du wiederholt tust, Glück sowie körperliche und mentale Fitness werden zur Gewohnheit.",
            img: FitnessImg,
            imgAlt: "Fitness Icon",
          },
        ]}
      />
      {/* ToDo: Add the right pictures
      <IIIStep
        service
        ltr
        columns="repeat(6, 1fr)"
        columnsTablet="repeat(4, 1fr)"
        columnsMobile="1fr"
        title="Unsere neuen Kurse ab Oktober: Jetzt buchen!"
        pathname={location.pathname}
        values={[
          {
            id: (
              <>
                Brazilian Jiu Jitsu(BJJ) <br /> Fundamentals{" "}
              </>
            ),
            title: "Brazilian Jiu Jitsu (BJJ) Fundamentals",
            alt: "Brazilian Jiu Jitsu (BJJ) Einsteigerkurs für Jugendliche und Erwachsene",
            img: data.magnet,
            to: "https://www.off-the-zone.com/bjj-fundamentals-2023",
            gridColumn: "1/3",
            gridColumnTablet: "1/3",
          },
          {
            id: (
              <>
                Mixed Martial Arts(MMA) <br /> Fundamentals{" "}
              </>
            ),
            title: "Mixed Martial Arts (MMA) Fundamentals",
            alt: "Mixed Martial Arts (MMA) Einsteigerkurs für Jugendliche & Erwachse",
            img: data.magnet,
            to: "https://www.off-the-zone.com/MMA-fuer-einsteiger",
            gridColumn: "3/5",
            gridColumnTablet: "3/5",
          },
          {
            id: (
              <>
                Boxsacktraining <br /> Auspowern am Sandsack{" "}
              </>
            ),
            title: "Boxsacktraining",
            alt: "Boxsacktraining: mit Schlag- und Trittechniken am Sandsack auspowern",
            img: data.magnet,
            to: "https://www.off-the-zone.com/kickboxsack-training-kurs",
            gridColumn: "5/7",
            gridColumnTablet: "1/3",
          },
          {
            id: (
              <>
                Yoga für Einsteiger <br /> Donnerstag Abend{" "}
              </>
            ),
            title: "Yoga für Einsteiger Donnerstag Abend",
            alt: "oga für Einsteiger <br /> Donnerstag Abend",
            img: data.magnet,
            to: "https://www.off-the-zone.com/yoga-einsteiger-kurs-level2",

            gridColumn: "1/3",
            gridColumnTablet: "3/5",
          },
          {
            id: (
              <>
                Yoga für Einsteiger <br /> Freitag Vormittag{" "}
              </>
            ),
            title: "Yoga für Einsteiger Freitag Vormittag",
            alt: "Yoga für Einsteiger Freitag Vormittag",
            img: data.magnet,
            to: "https://www.off-the-zone.com/yoga-einsteiger-kurs-vormittags",
            gridColumn: "3/5",
            gridColumnTablet: "1/3",
          },
          {
            id: (
              <>
                Yoga Armbalances <br /> Dienstag Abend{" "}
              </>
            ),
            title: "Yoga Armbalances Dienstag Abend",
            alt: "Yoga Armbalances Dienstag Abend",
            img: data.magnet,
            to: "https://www.off-the-zone.com/armbalances",
            gridColumn: "5/7",
            gridColumnTablet: "3/5",
          },
        ]}
      /> */}


      <ProblemBox
        mainValue={{
          img: StressImg,
          imgAlt: "Yoga und Kampfsport helfen gegen Stress",
          headline: "Hast Du diese Zweifel:",
        }}
        values={[
          {
            id: 2,
            problem: "Fitnesslevel",
            //description:
            //"Fitness ist einer der vielen positiven Effekte von Kampfsport & Yoga. Sobald du startest, baust du die nötige Kraft, Flexibilität, Ausdauer und Koordination in Windeseile auf."
          },
          {
            id: 3,
            problem: "Zeitmangel",
            //description:
            //"Prioritäten, nicht Zeitmangel, ist das Problem. Wenn Du mit uns trainierst, bekommst Du den Kopf frei, um Prioritäten neu zu setzen und Dein Alltag zu entzerren."
          },
          {
            id: 4,
            problem: "Anfänger",
            //description:
            //"Etwas Neues zu beginnen ist oft beängstigend. Mut zu haben bedeutet, es trotz Angst zu tun. In unserem Team trainieren alle Körpertypen, Level und Altersgruppen."
          },
          {
            id: 5,
            problem: "Erschöpfung",
            //description:
            //"Das Paradoxe ist: um Energievoller zu sein, muss man sich auspowern. Denn nach dem Training fühlst Du Dich freier, zufriedener und schläfst besser."
          },
        ]}
      />
      <Guide
        statement="Wir verstehen – ein ereignisreiches Arbeits- & Privatleben und regelmäßige Bewegung unter einen Hut zu bringen kann schwierig sein."
        img={data.guide}
        description={[
          <>
            Nachdem wir selbst die Auswirkungen von Stress im Büroleben, in der
            akademischen Welt und bei der Leitung unseres Unternehmens erfahren
            haben, haben wir hart gearbeitet, um unser körperliches und
            geistiges Wohlbefinden zu halten, während wir uns ständig
            herausfordern, neue Fähigkeiten zu entwickeln und unsere Grenzen zu
            verschieben. Wir bieten Dir unsere Unterstützung an.
          </>,
        ]}
        pathname={location.pathname}
      />
      <IIIStep
        service
        title="Unser Angebot"
        description=""
        values={[
          {
            id: "Yoga",
            img: data.yogaService,
            to: "/yoga-muenchen/",
          },
          {
            id: "BJJ",
            img: data.bjjService,
            to: "/bjj-muenchen/",
          },
          {
            id: "MMA",
            img: data.mmaService,
            to: "/mma-kickboxen-muenchen/",
          },
        ]}
      />
      <TrustBox
        title="Unser Yoga- und Kampfsportangebot überzeugt:"
        values={[
          {
            id: 1,
            img: data.trust1,
            trust: "Kampfsportschule des Jahres 2020",
          },
          {
            id: 5,
            img: data.trust5,
            trust: "Yogalehrerausbildung in Indien von Lalit Kumar",
          },
          {
            id: 3,
            img: data.trust3,
            trust: "National und International erfolgreich",
          },
          {
            id: 6,
            img: data.trust6,
            trust: "BJJ Graduierung duch Cadu Francis",
          },
          {
            id: 4,
            img: data.trust4,
            trust: "Begeisterte Kunden",
          },
          {
            id: 2,
            img: data.trust2,
            trust: "IBJJF Zertifiziert",
          },
        ]}
      />
      <IIIStep
        title="Jede größere Änderung beginnt mit einem Schritt!"
        description="Egal, ob Du nach der Arbeit Stress abbauen, abnehmen, Dich in Form bringen oder Dich körperlich oder mental herausfordern möchtest, Team Laurien & Alex hilft Dir dabei, Dein Ziel zu erreichen."
        values={[
          {
            id: 1,
            img: data.step1,
            value: "Schritt 1 ",
            statement: "Buche Deine kostenlose Probestunde noch heute!",
          },
          {
            id: 2,
            img: data.step2,
            value: "Schritt 2",
            statement:
              "Habe Spaß beim Training mit dem Team & freue Dich auf fortlaufende Unterstützung.",
          },
          {
            id: 3,
            img: data.step3,
            value: "Schritt 3",
            statement:
              "Erfahre wie sich Deine körperliche und geistige Fitness verbessert!",
          },
        ]}
      />
      <VideoBox
        title="Wir unterrichten alle Stunden mit einem Traumainformierten Ansatz"
        description={[
          "Das Video zeigt den Erfolg eines unserer Mitglieder. Als er als 6-Jähriger bei uns mit BJJ anfing, wurde er heftig gemobbt. Ein Jahr später war er selbstsicherer und voller Selbstvertrauen. Er kämpfte in Salzburg zum ersten Mal und gegen 10kg schwerere Gegner! Er gewann jeden Kampf und sicherte sich die Goldmedaille!",
          "... Mittlerweile haben sich seine Skills dank der fürsorglichen und kompetenten Hinwendung durch Laurien und Alex so stark verbessert, dass er sogar auf Turnieren im Ausland Gold gewinnt. ...",
        ]}
        url="wg2XEijZQGo"
        pathname={location.pathname}
      />

      <Testimonials
        title="Das sagen unsere Mitglieder:"
        values={[
          {
            id: 4,
            review:
              "TLA gibt es nun mehr denn 2 Jahre. Mein Sohn (8 Jahre) ist seit der ersten Stunde BJJ dabei und immer noch Feuer und Flamme für diesen Kampfsport. Er hatte sich nie für irgendein Hobby interessiert, doch gleich nach der ersten kostenlosen Probestunde statt für ihn fest, das gefällt ihm, hier will er bleiben. Mittlerweile haben sich seine Skills dank der fürsorglichen und kompetenten Hinwendung durch Laurien und Alex so stark verbessert, dass er sogar auf Turnieren im Ausland Gold gewinnt. Wir, die Eltern, sind mittlerweile auch feste Mitglieder bei TLA und trainieren regelmäßig bei MMA und BJJ mit. Somit sind Laurien und Alex ein Gewinn für unsere ganze Familie geworden und wir möchten Sie nicht mehr missen!!!",
            reviewer: "Susanne R.",
            platform: "meunchen.de",
            img:
              "https://cdn.shortpixel.ai/spai/q_glossy+ret_img+to_webp/https://lh4.ggpht.com/-tjAsb2ibil4/AAAAAAAAAAI/AAAAAAAAAAA/RIE7jEoWFnU/s128-c0x00000000-cc-rp-mo-ba2/photo.jpg",
          },
          {
            id: 1,
            review:
              "Kleines, sauberes Gym mit familiärer Atmosphäre. Laurien und Alex machen ihren Job sauber und mit Herz. Sie gehen toll mit Kindern um, haben ein super Verhältnis zu den Jugendlichen und jeder wird als Freund empfangen und nicht als zahlender Kunde gesehen. Hier stimmt das Verhältnis zwischen Härte und Herz. Fachlich und technisch sind die zwei Top und ergänzen sich gut. Die Trainings sind super und am nächsten Tag weiß man, was man getan hat 👍🏻",
            reviewer: "Sebastian R.",
            platform: "google.de",
            img:
              "https://cdn.shortpixel.ai/spai/q_glossy+ret_img+to_webp/https://lh4.ggpht.com/-tjAsb2ibil4/AAAAAAAAAAI/AAAAAAAAAAA/RIE7jEoWFnU/s128-c0x00000000-cc-rp-mo-ba2/photo.jpg",
          },
          {
            id: 2,
            reviewer: "Nacht Glocke",
            img:
              "https://lh3.ggpht.com/-EF1fjB92uWs/AAAAAAAAAAI/AAAAAAAAAAA/6Yz6efcUG2g/s128-c0x00000000-cc-rp-mo/photo.jpg",
            review:
              "Tolle Kampfsportschule, Laurin und Alex sind mega sympathisch und sehr professionell. Die Kampfsportstunden machen richtig Spaß und Yoga ist auch super. Vor allem die Aktion im Sommer #yogaamfasaneriesee, bei der erfolgreich Geld für die Münchner Tafel gesammelt wurde. Große Empfehlung",
            platform: "google.de",
          },
          {
            id: 3,
            reviewer: "macmel 2",
            img:
              "https://lh4.ggpht.com/-BZVPNvS1X0I/AAAAAAAAAAI/AAAAAAAAAAA/VgwMgc_iE4Q/s128-c0x00000000-cc-rp-mo/photo.jpg",
            review:
              "Ein Super Gym!!! Echt tolle Leute und super Trainer mit Erfahrung, jedes mal lernt man was neues und wird im in seinen individuellen Stärcken aber auch Scheächen gestärkt. Kann man echt nur weiter empfehlen, wenn jemand ein neues Gym mit familiärer Atmosphäre sucht!",
            platform: "google.de",
          },
        ]}
      />
      <div>
        <ContactForm />
      </div>

      <H2 center width="fit-content" margin="auto">
        Neues bei TLA
      </H2>
      {
        // <span>Alle {data.allMarkdownRemark.totalCount} Posts anschauen</span>
      }
      <Posts data={data} />
      <Download image={data.magnet} />
    </Layout>
  )
}

export const query = graphql`{
  allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}, limit: 10) {
    totalCount
    edges {
      node {
        id
        frontmatter {
          title
          date
          slug
          featuredImage {
            childImageSharp {
              gatsbyImageData(
                width: 300
                quality: 100
                placeholder: NONE
                layout: CONSTRAINED
              )
            }
          }
        }
        fields {
          slug
        }
        excerpt
      }
    }
  }
  hero1: file(relativePath: {eq: "Spass_beim_BJJ_in_Muenchen.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  hero2: file(relativePath: {eq: "Kickboxen-MMA-Muenchen.JPG"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  hero3: file(relativePath: {eq: "MMA/Kampfsoport-München-MMA-Kickboxen-Jugend.JPG"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  hero4: file(
    relativePath: {eq: "Teep_auf_Muay_Thai_Pads_Kickboxen_MMA_Muenchen.JPG"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  hero5: file(relativePath: {eq: "BJJ/BJJ-München-rolling.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  hero6: file(relativePath: {eq: "BJJ-Problemloesend-denken.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  hero7: file(relativePath: { eq: "Yoga/Yoga-am-Fasaneriesee-Laurien-Dehnung.jpg" }) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  hero8: file(relativePath: {eq: "Therapiehuendin-yuki-hilft.JPG"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  guide: file(relativePath: {eq: "Body-kick-Muay-Thai-Style-TLA-Muenchen.jpeg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  trust1: file(
    relativePath: {eq: "Kampfsportschule-des-Jahres-Team-Laurien-Alex.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust2: file(relativePath: {eq: "IBJJF-Zerifikat-TLA-BJJ.JPG"}) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust3: file(relativePath: {eq: "Deutsche-Meister-BJJ-IBJJF-Kickboxen.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust5: file(
    relativePath: {eq: "Yogalehrerzertifikat-in-indien-von-Lalit-Kumar-Himalaya-Yoga-Valley.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust4: file(relativePath: {eq: "Reviews-Team-Laurien-Alex.JPG"}) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust6: file(
    relativePath: {eq: "Laurien-and-alex-with-Cadu-Francis-purple-belt-graduation.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  yogaService: file(relativePath: {eq: "Yoga-Muenchen-twist.JPG"}) {
    childImageSharp {
      gatsbyImageData(
        width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  bjjService: file(
    relativePath: {eq: "BJJ-Muenchen-overhead-sweep-snake-guard.jpeg"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  mmaService: file(relativePath: {eq: "MMA-Muenchen-double-leg-takedown.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  step1: file(
    relativePath: {eq: "Persoenliche-Unterstuetzung-beim-kostenlosen-Probetraining-TLA-Muenchen.JPG"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 400
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  step2: file(relativePath: {eq: "Spass-beim-Training-im-Team-Muenchen.JPG"}) {
    childImageSharp {
      gatsbyImageData(
        width: 400
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  step3: file(
    relativePath: {eq: "Fitness-verbessern-team-laurien-alex-chaturanga-liegestuetz.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 400
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  magnet: file(relativePath: {eq: "Cover_Bist_du_gestresst_atemuebungen.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 400
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
}
`
export default Index
