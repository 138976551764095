import React from "react"
import styled from "styled-components"

import globals from "./globals"
import { Container, Grid, ContentBox, P, H2 } from "./styledcomponents"

const Img = styled.img``

function Box({ value, statement, img, imgAlt }) {
  return (
    <ContentBox
      backgroundColor="white"
      color="black"
      shadow
      margin="1em 0"
      padding="5%"
      width="90%"
    >
      {img ? <Img src={img} alt={imgAlt} width="76" height="76" /> : undefined}
      <H2 color={globals.color.main} noLine center>
        {value}
      </H2>
      {statement ? <P center>{statement}</P> : undefined}
    </ContentBox>
  )
}

function ValueBox(props) {
  return (
    <Container backgroundColor="white" overflow="visible">
      <Grid
        margin="-4em 1em 4em 1em;"
        columns={`repeat(${props.values.length}, 1fr )`}
        width="90%"
        gridGap="2em"
      >
        {props.values.map(({ id, ...otherProps }) => (
          <Box key={id} {...otherProps} />
        ))}
      </Grid>
    </Container>
  )
}

export default ValueBox
