import React from "react"

import { ContentBox, FlexBox2, Li, H2, P } from "./styledcomponents"
import globals from "./globals"

function Box({ problem, description, id }) {
  return (
    <Li id={id}>
      {id === 1 ? <H2 left>{problem}</H2> : <P left>{problem}</P>}
      {description && <P left>{description}</P>}
    </Li>
  )
}

function ProblemBox(props) {
  return (
    <FlexBox2 maxWidth={globals.media.tablet} alignItems="center">
      <img
        width="300px"
        height="300px"
        src={props.mainValue.img}
        alt={props.mainValue.imgAlt}
      />
      <ContentBox
        margin="0"
        padding="2em 0"
        maxWidth={globals.media.mobile}
        alignSelf="left"
      >
        <H2 left>{props.mainValue.headline}</H2>
        <ul>
          {props.values.map(({ id, ...otherProps }) => (
            <Box key={id} id={id} {...otherProps} />
          ))}
        </ul>
      </ContentBox>
    </FlexBox2>
  )
}

export default ProblemBox
